import React, { useReducer, useState } from "react";
import "./contactStyle.css";
import axios from "axios";
import Button from "../../../ui/Button";

const Contact = ({ data }) => {
   const [userInput, setUserInput] = useReducer(
      (state, newState) => ({
         ...state,
         ...newState,
      }),
      {
         name: "",
         email: "",
         msg: "",
      }
   );
   const [success, setSuccess] = useState(null);
   const [errorMsg, setErrorMsg] = useState("");

   const handleChange = (e) => {
      const { name, value } = e.target;
      setUserInput({ [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (userInput.name === "") {
         setSuccess(false);
         setErrorMsg("Skriv in ditt namn");
         return;
      }
      if (userInput.email === "") {
         setSuccess(false);
         setErrorMsg("Skriv in din email");
         return;
      }
      if (userInput.msg === "") {
         setSuccess(false);
         setErrorMsg("Skriv ett meddelande");
         return;
      }
      const simpleEmailRegex = /\S+@\S+\.\S+/;
      if (!simpleEmailRegex.test(userInput.email)) {
         setSuccess(false);
         setErrorMsg("Skriv in en giltig email");
         return;
      }
      axios
         .post(
            "https://server02.blackpixel.se:1402/send/0adb4ca66bab41e7d8f0db181397471d/b3cc2d1d93898b7b936a883d2f9aa624",
            {
               subject: "Meddelande från hemsidan - hoselectra.se",
               text: `<h1>${userInput.name}</h1><p>${userInput.email}</p><p>${userInput.msg}</p>`,
            }
         )
         .then((response) => {
            setSuccess(true);
            setUserInput({
               name: "",
               email: "",
               msg: "",
            });

            if (typeof window.gtag !== "undefined") {
               window.gtag("event", "form_submit", {
                  event_category: "contact",
                  event_label: "contact form",
               });
            }

         })
         .catch((error) => {
            console.log(error);
            setSuccess(false);
            setErrorMsg("Email could not be sent");
         });
   };

   return (
      <div className="contact">
         <span className="linkDestination" id={data.linkid}></span>
         <h3 className="contactHeader">
            <span className="headerNr">{data.sectionNr}</span>
            <span className="headerHeader">{data.header}</span>
         </h3>
         <p className="contactIngress">{data.ingress}</p>

         <form>
            <div className="inputContainer">
               <div className="formRow">
                  <input
                     name="name"
                     className="nameInput"
                     value={userInput.name}
                     onChange={handleChange}
                     placeholder={data.form.namePlaceholder}
                  />
                  <div className="mailInputContainer">
                     <input
                        name="email"
                        placeholder={data.form.mailPlaceholder}
                        value={userInput.email}
                        onChange={handleChange}
                     />
                  </div>
               </div>
            </div>
            <textarea
               name="msg"
               className="msgTextarea"
               value={userInput.msg}
               onChange={handleChange}
               placeholder={data.form.msgPlaceholder}
            />
         </form>
         <Button handleClick={handleSubmit}>{data.form.btnPlaceholder}</Button>
         {/* <button onClick={handleSubmit}> {data.form.btnPlaceholder} </button> */}
         <span className="successMsg">
            {success
               ? "Tack för ditt meddelande. Vi återkommer så fort som möjligt!"
               : errorMsg}
         </span>
      </div>
   );
};

export default Contact;

import React from "react";
import "./loadingScreen.css";
const LoadingScreen = ({ loading }) => {
   const loadingComplete = loading ? "loading" : "loadingComplete";
   return (
      <div className={`loadingScreen ${loadingComplete}`}>
         <p>Välkommen...</p>
      </div>
   );
};

export default LoadingScreen;

import React, { useState, useEffect } from "react";
import WindowSize from "./contexts/windowSize/WindowSize";
import "./normalizer.css";
import "./appStyle.css";
import Axios from "axios";

// components
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import LoadingScreen from "./components/main/LoadingScreen/LoadingScreen";
import CookieModal from "./components/CookieModal";

function App() {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(true);


	// Ex: { necessary: true, analytics: true, marketing: false }
	const [acceptedCookies, _setAcceptedCookies] = useState({ necessary: true, analytics: false, marketing: false })

	useEffect(() => {
		if (typeof localStorage != 'object') return;
		const cookies = JSON.parse(localStorage.getItem('cookies'))
		if (cookies) setAcceptedCookies(cookies)
	}, [])

	const setAcceptedCookies = (cookies) => {
		_setAcceptedCookies(acceptedCookies => {
			let newCookies = {}
			if (typeof cookies == 'object') {
				newCookies = {
					...acceptedCookies,
					...cookies
				}
			} else if (typeof cookies == 'function') {
				newCookies = cookies(acceptedCookies)
			}

			if (typeof window.gtag !== 'undefined') {
				window.gtag('consent', 'update', {
					'ad_storage': newCookies.marketing ? 'granted' : 'denied',
					'ad_user_data': newCookies.marketing ? 'granted' : 'denied',
					'ad_personalization': newCookies.marketing ? 'granted' : 'denied',
					'analytics_storage': newCookies.analytics ? 'granted' : 'denied',
					'personalization_storage': newCookies.analytics ? 'granted' : 'denied',
					'security_storage': newCookies.analytics ? 'granted' : 'denied',
					'functionality_storage': newCookies.analytics ? 'granted' : 'denied'
				});
			}

			if (typeof localStorage != 'object') return;
			localStorage.setItem('cookies', JSON.stringify(newCookies))

			return newCookies
		})
	}

   useEffect(() => {
      Axios.get("https://hoselectra.se/wp/wp-json/wp/v2/pages/94")
         .then((response) => {
            // handle success
            setData(response.data.acf);
            setLoading(false);
         })
         .catch((error) => {
            // handle error
         });
   }, []);

   return (
      data && (
         <>
            {<LoadingScreen loading={loading} />}
            <WindowSize>
               <div className="app">
                  <Header data={data.header} />
                  <Main data={data} />
                  <Footer data={data.footer} />
            		<CookieModal acceptedCookies={acceptedCookies} setAcceptedCookies={setAcceptedCookies} />
               </div>
            </WindowSize>
         </>
      )
   );
}

export default App;

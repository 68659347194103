import React from 'react';
import './infoBoxesStyle.css';




const InfoBoxes = ({data}) => {
    return (
        <div className="infoBoxes">
            <span className="linkDestination" id={data.linkId}></span>

            {
                data.boxes.map((box, idx) => {
                    return (
                        <div className="boxContainer" key={idx} style={{ order: box.order }}>
                            <img src={box.img.url} alt="glitter"/>
                            <h3>
                                <span className="headerNr"> {box.sectionNr} </span>
                                <span className="headerHeader"> {box.header} </span>
                            </h3>
                            <p>
                                {box.ingress}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default InfoBoxes;
import React, { useContext, useEffect, useState } from "react";
import "./firstPageStyle.css";
import Button from "../../../ui/Button";
import { BreakpointContext } from "../../../contexts/windowSize/WindowSize";

const FirstPage = ({ data }) => {
   const { breakPoint } = useContext(BreakpointContext);
   const [backgroundImg, setBackgroundImg] = useState(null);

   useEffect(() => {
      if (backgroundImg !== data.backgroundimg.url) {
         if (breakPoint === "desktop") {
            setBackgroundImg(data.backgroundimg.url);
         } else if (breakPoint === "tablet") {
            setBackgroundImg(data.backgroundimg.sizes.large);
         } else if (
            breakPoint === "mobile" &&
            backgroundImg !== data.backgroundimg.sizes.large
         ) {
            setBackgroundImg(data.backgroundimg.sizes.medium_large);
         }
      }
   }, [breakPoint]);

   return (
      <div className="firstPage">
         <span className="linkDestination" id={data.linkId}></span>
         <img className="firstPageBackgroundImg" src={backgroundImg} alt="nails" />
         <div className="firstPageContent">
            <img className="firstPageTextImg" src={data.textimg.url} alt="Logo" />
            <a
               className="firstPageBookLink"
               href={data.button.url}
               target="_blank"
               rel="noopener noreferrer"
            >
               <Button>{data.button.title}</Button>
            </a>
         </div>
      </div>
   );
};

export default FirstPage;

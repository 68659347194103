import React, { useState } from 'react';
// import { setCookie } from 'cookies-next';
import styled from 'styled-components';
import Checkbox from '../elements/Checkbox';

export default function CookieModal({ acceptedCookies, setAcceptedCookies }) {

    const [openSettings, setOpenSettings] = useState(false)

    const handleAccept = () => {
        setOpenSettings(false)
        setAcceptedCookies(acceptedCookies => ({
            ...acceptedCookies,
            necessary: true,
            analytics: true,
            marketing: true,
            hasDecided: true
        }))

    };

    const handleDecline = () => {
        setOpenSettings(false)
        setAcceptedCookies(acceptedCookies => ({
            ...acceptedCookies,
            necessary: true,
            analytics: false,
            marketing: false,
            hasDecided: true
        }))

        setTimeout(() => window.location.reload(), 100)
    };

    const handleOnChange = (e) => {
        const cookieName = e.target.name;

        setAcceptedCookies({
            ...acceptedCookies,
            [cookieName]: !acceptedCookies[cookieName]
        })
    }
    
    const hasDecided = () => {
        setAcceptedCookies({
            ...acceptedCookies,
            hasDecided: true
        })
    }

    return (
        <Style id="cookie-modal" className="cookie-modal hide-print">
            
            {/* Render the Cookie Consent bar if no cookies has been accepted. */}
            {acceptedCookies['hasDecided'] != true && (
                <div className="cookie-modal__content">
                    <p>Vi använder cookies för att ge dig den bästa upplevelsen på vår hemsida. Du kan ta reda på mer om vilka cookies vi använder eller stänga av dem i inställningar (<span onClick={() => setOpenSettings(true)}>anpassa val</span>).</p>
                    <button onClick={handleAccept}>Godkänn kakor</button>
                    <button className="outlined" onClick={handleDecline}>Avböj kakor</button>
                    <button className="outlined" onClick={() => setOpenSettings(true)}>Anpassa val</button>
                </div>
            )}

            {/* If the user has accepted, render a button to open the settings. */}
            {acceptedCookies['hasDecided'] == true && (
                <div className="cookie-open_settings_icon" onClick={() => setOpenSettings(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.4301 12.98C19.4701 12.66 19.5001 12.34 19.5001 12C19.5001 11.66 19.4701 11.34 19.4301 11.02L21.5401 9.37C21.7301 9.22 21.7801 8.95 21.6601 8.73L19.6601 5.27C19.5401 5.05 19.2701 4.97 19.0501 5.05L16.5601 6.05C16.0401 5.65 15.4801 5.32 14.8701 5.07L14.4901 2.42C14.4601 2.18 14.2501 2 14.0001 2H10.0001C9.75008 2 9.54008 2.18 9.51008 2.42L9.13008 5.07C8.52008 5.32 7.96008 5.66 7.44008 6.05L4.95008 5.05C4.72008 4.96 4.46008 5.05 4.34008 5.27L2.34008 8.73C2.21008 8.95 2.27008 9.22 2.46008 9.37L4.57008 11.02C4.53008 11.34 4.50008 11.67 4.50008 12C4.50008 12.33 4.53008 12.66 4.57008 12.98L2.46008 14.63C2.27008 14.78 2.22008 15.05 2.34008 15.27L4.34008 18.73C4.46008 18.95 4.73008 19.03 4.95008 18.95L7.44008 17.95C7.96008 18.35 8.52008 18.68 9.13008 18.93L9.51008 21.58C9.54008 21.82 9.75008 22 10.0001 22H14.0001C14.2501 22 14.4601 21.82 14.4901 21.58L14.8701 18.93C15.4801 18.68 16.0401 18.34 16.5601 17.95L19.0501 18.95C19.2801 19.04 19.5401 18.95 19.6601 18.73L21.6601 15.27C21.7801 15.05 21.7301 14.78 21.5401 14.63L19.4301 12.98ZM12 15.5C10.07 15.5 8.5 13.93 8.5 12C8.5 10.07 10.07 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5Z" fill="black"/>
                    </svg>
                </div>
            )}

            
            {/* Render the Cookie Settings popup based on the state of openSettings. */}
            {openSettings && (
                <>
                    <div className="overlay" onClick={() => setOpenSettings(false)}></div>
                    <div className="cookie-modal__settings">
                        <div className="h3">Inställningar av cookies</div>
                        <div className="spacer xs"></div>
                        <p className="small">Denna webbplats använder cookies för att vi ska kunna ge dig den bästa möjliga användarupplevelsen. Cookieinformation lagras i din webbläsare och utför funktioner som att känna igen dig när du återvänder till vår webbplats och hjälpa vårt team att förstå vilka delar av webbplatsen du tycker är mest intressanta och användbara.</p>
                        <div className="spacer m"></div>

                        <div className="cookie-group">
                            <div className="h4">Nödvändiga cookies</div>
                            <div className="spacer xs"></div>
                            <p className="small">Dessa cookies är nödvändiga för att webbplatsen ska fungera och kan inte inaktiveras i våra system. De används vanligtvis bara i samband med svar som du gör i en tjänst, såsom att sätta dina cookie-inställningar, inloggning eller fylla i formulär. Dessa cookies går inte att inaktivera och genom att använda webbplatsen godkänner du att vi kan placera dessa typer av cookies på din enhet.</p>
                        </div>

                        <div className="cookie-group">
                            <div className="h4"><Checkbox name="analytics" checked={acceptedCookies['analytics'] ?? false} onClick={handleOnChange}>Statistikcookies</Checkbox></div>
                            <div className="spacer xs"></div>
                            <p className="small">Denna webbplats använder Google Analytics för att samla in anonym information såsom antalet besökare på hemsidan och de mest populära sidorna.</p>
                        </div>

                        <div className="cookie-group">
                            <div className="h4"><Checkbox name="marketing" checked={acceptedCookies['marketing'] ?? false} onClick={handleOnChange}>Marknadsföringscookies</Checkbox></div>
                            <div className="spacer xs"></div>
                            <p className="small">Cookies för marknadsföring används för att spåra besökare på webbplatser. Avsikten är att visa annonser som är relevanta och engagerande för enskilda användare, och därmed mer värdefull för utgivare och tredjepartsannonsörer.</p>
                        </div>

                        <div className="spacer m"></div>
                        <div className="buttons">
                            <button onClick={() => {
                                setOpenSettings(false)
                                hasDecided()
                            }}>Spara</button>
                            <button onClick={handleAccept}>Godkänn alla</button>
                            <button className="outlined" onClick={handleDecline}>Avböj alla</button>
                        </div>
                    </div>
                </>
            )}

        </Style>
    );
}

const Style = styled.section`

    .h3 {
        font-size: 1.5rem;
        font-weight: bold;
        font-family: 'Raleway', sans-serif;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }


    .h4 {
        font-size: 1.2rem;
        font-weight: bold;
        font-family: 'Raleway', sans-serif;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .cookie-modal__content {
        z-index: 100;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: white;
        padding: 1rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #eee;

        p {
            flex: 1;
            font-size: 0.8rem;
            max-width: 800px;
            margin-right: auto;

            span {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        button {
            margin-left: 1rem;
            font-size: 0.8rem;
        }
    }

    .overlay {
        z-index: 101;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.75);
    }

    .cookie-modal__settings {
        z-index: 102;
        position: fixed;
        top: 50%;
        left: 50%;
        width: calc(100% - 2rem);
        max-width: 800px;
        max-height: 90vh;
        overflow-y: auto;
        background: white;
        padding: 1rem;
        transform: translate(-50%, -50%);

        .cookie-group {
            margin-bottom: 2rem;

        }


        .buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 1rem;

            button {
                margin-left: 1rem;
                font-size: 0.8rem;
            }
        }
    }

    .cookie-open_settings_icon {
        z-index: 100;
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        background-color: #555;
        padding: 0.25rem;

        svg {
            width: 100%;
            height: 100%;

            path {
                fill: #fff;

                &:hover {
                    fill: #eee;
                }
            }
        }

    }

    .mobile & {

        .cookie-modal__content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 0.5rem;
            grid-column-gap: 0.5rem;
            padding: 1rem;

            * {
                grid-column: span 2;
            }

            p {
                margin-right: 0;
            }

            button {
                margin-left: 0;


                &:nth-of-type(1) {
                    grid-column: span 2;
                }

                &:nth-of-type(2) {
                    grid-column: span 1;
                }

                &:nth-of-type(3) {
                    grid-column: span 1;
                }
            }
        }


        .cookie-modal__settings {
            
            .buttons {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                margin-top: 1rem;

                button {
                    margin-left: 0;
                    margin-top: 1rem;
                }
            }
        }

    }

    button {
        background-color: #d259c7;
        border: none;
        border-radius: 10rem;
        padding: 0.5rem 1rem;
        color: white;
        font-size: 20px;
        font-weight: bold;

        &:hover {
            background-color: #c04ab7;
        }

        &.outlined {
            background-color: transparent;
            border: 2px solid #d259c7;
            color: #d259c7;

            &:hover {
                background-color: #d259c7;
                color: white;
            }
        }
    }



`
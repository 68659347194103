import React from 'react';
import './footerStyle.css';


const Footer = ({data}) => {
    return (
        <footer className="footer">
            <section className="part1">
                <img src={data.companyLogo.logo.url} alt="company logo" />
                <p> {data.companyLogo.text} </p>
            </section>
            <section className="part2">
                <h3> {data.contact.header} </h3>
                <div>
                    {
                        data.contact.text.address.map((p, idx) => {
                            return (
                                    <p key={idx}>{p.text}</p>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        data.contact.text.contactinfo.map((p, idx) => {
                            return (
                                    <p key={idx}>{p.text}</p>
                            )
                        })
                    }
                </div>
                <div className="googleMapContainer">
                    <a target="_blank" rel="noopener noreferrer" href={data.contact.googleMap.url} alt="google map address">{data.contact.googleMap.title}</a> 
                </div>
            </section>
            <section className="part3">
                <h3>{data.social.header}</h3>  
                <div className="socialLinks">              
                    <a target="_blank" rel="noopener noreferrer" href={data.social.facebook.link.url}> <img src={data.social.facebook.img.url} alt="Link to facebook page" />  </a>
                    <a target="_blank" rel="noopener noreferrer" href={data.social.instagram.link.url}><img src={data.social.instagram.img.url} alt="Link to instagram page" /></a>
                </div>
            </section>
        </footer>
    )
}

export default Footer;
import React from "react";
import "./mainStyle.css";
import FirstPage from "./firstPage/FirstPage";
import Welcome from "./welcome/Welcome";
import InfoBoxes from "./infoBoxes/InfoBoxes";
import Contact from "./contact/Contact";
import FiftyFifty from "./fiftyFifty/FiftyFifty";
import SingularImg from "./singularImg/SingularImg";
import Text from "../text/Text";

const Main = ({ data }) => {
   return (
      data && (
         <main className="main">
            {data.firstpage && <FirstPage data={data.firstpage} />}
            {data.welcome && <Welcome data={data.welcome} />}
            {data.fiftyfifty && <FiftyFifty data={data.fiftyfifty} />}
            {data.infoBoxes && <InfoBoxes data={data.infoBoxes} />}
            {data.contact && <Contact data={data.contact} />}
            {data.singular_img && <SingularImg data={data.singular_img} />}
            {data.text && <Text data={data.text} />}
         </main>
      )
   );
};

export default Main;

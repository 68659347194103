import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


function useRandomID() {
    const [id, setId] = useState(null)

    useEffect(() => {
        setId(Math.random().toString(36).substring(7))
    }, [])

    return id
}

export default function Checkbox({ children, wrapperClassName, value, name, ...rest }) {

    let random = useRandomID()
    return (
        <Style className={`checkbox ${wrapperClassName}`} htmlFor={random}>{children}
            <input type="checkbox" value={value ?? children} id={random} name={name} {...rest} />
            <span className="checkmark"></span>
        </Style>
    )
}

const Style = styled.label`
    --cb-size: 1em;
    --color-dark-gray: #333;
    --color-light-gray: #ccc;
    --color-red: #f00;

    position: relative;
    padding-left: 1.5em;
    line-height: 1em;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: inherit;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:focus ~ .checkmark {
            outline: calc(1em / 16) var(--color-dark-gray) solid;
            outline-offset: calc(1em / 16);
        }

        &:checked ~ .checkmark {

            &:after, &:before {
                display: block;
            }
        }
    }

    span.checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: var(--cb-size);
        width: 1em;
        height: 1em;
        background-color: #fff;
        border: solid 2px var(--color-light-gray);

        &:before {
            content: "";
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -70%) rotate(-50deg);
            width: 0.5em;
            height: 0.2em;
            border-bottom: solid 2px;
            border-left: solid 2px;
            border-color: var(--color-red);
        }

    }

    &.radio {

        span.checkmark {

            border-radius: var(--cb-size);

            &:after {
                border-radius: var(--cb-size);
            }
        }

    }

    .mobile & {
        font-size: calc(20rem / 19.2);
    }
`